<template>
    <div>
      <v-card class="pa-3 grey lighten-2">
        <v-card-title>
          <span>
            Getaccesses groups items
          </span>
          <v-spacer></v-spacer>
          <v-btn :to="{name: 'clientsView'}" outlined color="error">cancel</v-btn>
        </v-card-title>
          <v-simple-table>
            <template>
              <thead>
                <tr>
                  <th>№</th>
                  <th>Items</th>
                  <th>Id</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(item, itemIndex) in Group" :key="itemIndex">
                  <td>{{itemIndex +1}}</td>
                  <td>{{item.name}}</td>
                  <td>{{item.id}}</td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
      </v-card>
    </div>
</template>

<script>
export default {
  name: 'GetAccesses',
  data() {
    return {
      Group: [],
    }
  },
  methods: {
  
   },
  created () {

    let id = this.$route.params.id
    this.$http.get(`Client/getAccesses`).then((res)=>{
      if (res && res.result && res.result.groups && res.result.groups.length > 0) {
        this.selectItem = res.result.groups
        this.Group = res.result.groups[0].items
        // console.log(res.result.modules[0].id);
      }
      if (res && res.result) {
        // console.log(res.result.groups[0].items)
      } else {
        this.$router.push({name: 'clientSecond'})
      }
    })
  },
}
</script>
